import React from "react";
import { experiences, projects, skills, education } from "./about-data";
import ScrollableAnchor from "react-scrollable-anchor";
import "./about.css";

const AboutSection = () => {
  const sectionRenderer = (info, listform) => {
    return info.map((exp, i) => {
      return (
        <div key={`title-${Math.random()}-${i}`}>
          <h4 className='aboutTitle'>{exp.title}</h4>
          <p className="lead mb-5 aboutContent">
            {
              exp.contents.map((content,i) => listform ? <li key={`list-${Math.random()}-${i}`}>{content}</li> : <span key={`list-${Math.random()}-${i}`}>{content}{i === exp.contents.length - 1 ? "" : ","} </span>)
            }
          </p>
        </div>
      )
    })
  }

  return (
    <ScrollableAnchor id="about">
      <section className="content-section aboutBack">
        <div className="container text-center">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <h1>
                Resume
              </h1>

              <h3 className="aboutHeader">Professional Experience</h3>

                {sectionRenderer(experiences, true)}

              <h3 className="aboutHeader">Project Experience</h3>
                {sectionRenderer(projects, true)}

              <h3 className="aboutHeader">Skills</h3>
                {sectionRenderer(skills)}
              
              <h3 className="aboutHeader">Education</h3>
                {sectionRenderer(education)}
                
              <p>
                <a href="./pdf/daniel-hsu-resume.pdf" download=''><i className="fas fa-file-download pr-2"></i> Download My Resume!</a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </ScrollableAnchor>
  );
}


export default AboutSection;
