
export const experiences = [
    {
        title: "RumbleUp/Switchboard: Full-Stack Developer",
        contents: [
          "Collaborated on cross-functional teams to create robust and responsive code using JavaScript, Node.js, and Knockout.js.",
          "Led the development and maintenance of a ChatGPT integration, elevating user interaction.",
          "Engineered and maintained a Salesforce Integration, improving data synchronization and operational efficiency.",
          "Designed and launched new website user interfaces, significantly boosting user engagement metrics."
        ]
    },
    {
        title: "Jaanuu: Software Engineer",
        contents: [
          "Collaborated in cross-functional teams using React, Ruby on Rails, and Vue, delivering reusable and responsive code within a Kanban waterfall environment.",
          "Led A/B testing initiatives with Google Optimize and Heap, refining features and optimizing conversions, providing valuable business insights.",
          "Worked closely with designers, stakeholders, and developers, contributing to design feasibility and innovative feature ideation and production.",
          "Managed ADA compliance for the website, ensuring enhanced accessibility and adherence to industry standards."
        ]
    },
    {
        title: "Centene Corporation: Application Software Developer",
        contents: [
            "Architected and implemented efficient, maintainable code in React and Node.js, optimizing project performance.",
            "Pioneered a Content Management System (CMS), streamlining workflows and enhancing productivity.",
            "Actively contributed to code reviews, specializing in feature feasibility and optimizing Git workflows.",
            "Established and maintained a robust Jest.js testing framework, ensuring high-quality, error-free code.",
            "Provided strategic input for transitioning to Scrum, facilitating a smoother, more agile development process."
        ]
    },
    {
      title: "Beauty By Design: Junior Full-Stack Engineer",
      contents: [
        "Developed clean, responsive, and maintainable code in React and Ruby on Rails with a Test-Driven Development (TDD) approach using RSpec.",
        "Contributed to Agile Scrum sprints, evaluating complexities and proposing solutions for diverse challenges.",
        "Practiced pair programming for high-quality code and knowledge sharing within the team.",
        "Monitored Heroku infrastructure for optimal performance.",
        "Designed and implemented a CMS for efficient marketing promotions management.",
      ]
    },
  ];

export const projects = [
    {
      title: "Agility – Agile Based Workflow Manager App",
      contents: [
        "Designed and created a work management app that simulates an AGILE workflow experience.",
        "Architected database structure in SQL/Sequelize.js, major frontend features using React.js and backend routing and server setting with Node.js",
        "Managed a team of 3 other people, with responsibilities of assigning tasks, dealing with bugs and running daily meetings to keep track of progress",
      ]
    },
    {
      title: "Curator – Grouped Restaurant Rating",
      contents: [
        "Designed and created a Yelp based rating app that allows users to rate a restaurant amongst people who share similar interests..",
        "Architected database structure in SQL/Sequelize.js, major frontend features with HTML, Javascript, jQuery and Handlebars.js as well as writing backend server code and routes in Node.js.",
        "Managed a team of 3 other people, with responsibilities of assigning tasks, dealing with bugs and running daily meetings to keep track of progress",
      ]
    },
    {
      title: "Itinerary – A Travel Itinerary App",
      contents: [
        "Designed and created a Yelp based app that lets a user plan their trips",
        "Wrote major features with Javascript, jQuery, Bootstrap and CSS.",
        "Managed a team of 2 other people, with responsibilities of assigning tasks, dealing with bugs and running daily meetings to keep track of progress",
      ]
    },
  ]

  export const skills = [
    {
        title: "Web Development Skills",
        contents: [
            "JavaScript",
            "Ruby",
            "Node.js",
            "HTML",
            "CSS",
            "Bootstrap",
            "Express.js"
        ]
    },
    {
        title: "Frameworks",
        contents: [
            "React.js",
            "Ruby on Rails",
            "Vue.js",
            "Knockout.js"
        ]
    },
    {
        title: "Database",
        contents: [
            "SQL",
            "PostgresSQL",
            "MongoDB",
            "DynamoDB",
            "Elasticsearch",
            "Firebase",
        ]
    },
    {
        title: "Testing",
        contents: [
            "RSpec",
            "Jest",
        ]
    },
    {
        title: "Other",
        contents: [
            "Heroku",
            "Git",
        ]
    },
  ]

  export const education = [
    {
        title: "University of California - Los Angeles",
        contents: [
            "Bachelor of Science in Electrical Engineering - December 2013",
        ]  
    },
    {
        title: "University of California - Los Angeles Extension",
        contents: [
            "MERN Stack Javascript Developer Program - November 2018",
        ]  
    }
  ]